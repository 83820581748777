<template>
  <section>
    <b-row class="mb-5">
      <b-col md="10">
        <h1>{{ $t("adsDisplay.ads") }}</h1>
        <h5 class="text-primary">
          {{ $t("adsDisplay.adsEdit") }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end mr-1">
        <div>
          <b-button
            variant="danger"
            @click="routeToEditView()"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-tabs
      id="ads-edit"
      pills
      @activate-tab="selectSlots"
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>{{ $t("editContent.title2") }}</span>
        </template>
        <b-row>
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showDatos"
            >
              <b-card>
                <b-card-header class="head">
                  <b-card-title>{{ $t("code.data") }}</b-card-title>
                  <h6 class="text-primary ">
                    {{ $t('adsInfo') }}
                  </h6>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col class="ml-2 mb-2">
                      <b-form @submit.prevent="updateInfo">
                        <!-- Title -->
                        <b-row>
                          <b-col>
                            <b-form-group :label="$t('dataGeneric.name') + ' *'">
                              <b-form-input
                                v-model="name"
                                autocomplete="new-password"
                                :placeholder="$t('dataGeneric.name')"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group :label="$t('code.activeAds')">
                              <b-form-checkbox
                                v-model="isActive"
                                :disabled="checkCategoryState(rootCategory)"
                                switch
                              />
                              <label class="root-category">{{ $t('adsDisplay.needActiveCatTip') }}
                                <strong>{{ checkCategoryState(rootCategory) ? '*' : '' }}</strong>
                              </label>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group :label="$t('dataGeneric.description')">
                              <b-form-textarea
                                id="textarea"
                                v-model="description"
                                :placeholder="$t('dataGeneric.description')"
                                rows="3"
                                max-rows="6"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-col style="text-align: end;">
                          <b-button
                            type="submit"
                            variant="success"
                          >
                            {{
                              $t("dataGeneric.save")
                            }}
                          </b-button>
                        </b-col>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :before-change="validationFormConfig">
        <template #title>
          <feather-icon icon="SettingsIcon" />
          <span>{{ $t('Configuration') }}</span>
        </template>
        <b-row>
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showDatos"
            >
              <b-card>
                <b-card-header class="head">
                  <b-card-title>{{ $t("adsDisplay.adsConfig") }}</b-card-title>
                  <h6 class="text-primary ">
                    {{ $t('adsDisplay.adsConfig-description') }}
                  </h6>
                </b-card-header>
                <b-card-body>
                  <validation-observer
                    ref="ConfigRules"
                    tag="form"
                  >
                    <form @submit.prevent="validationFormConfig()">
                      <b-card class="h-100">
                        <b-row>
                          <b-col md="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="rootCategoryName"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('adsDisplay.category')"
                                :invalid-feedback="$t('reCat')"
                                :state="errors.length > 0 ? false : null"
                              >
                                <div class="d-flex">
                                  <b-form-input
                                    id="rootCategoryName"
                                    v-model="rootCategoryName"
                                    disabled
                                    :placeholder="$t('adsDisplay.category-description')"
                                    :state="errors.length > 0 ? false : null"
                                  />
                                  <b-button
                                    class="ml-2"
                                    variant="primary"
                                    @click.stop.prevent="showModal('cat')"
                                  >
                                    {{ $t('Select') }}
                                  </b-button>
                                </div>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col md="6">
                            <b-form-group :label="$t('adsDisplay.adsPosition')">
                              <validation-provider
                                v-slot="{ errors }"
                                name="positionCategory"
                                rules="required"
                              >
                                <b-form-select
                                  v-model="positionCategory"
                                  :options="positionCategoryOptions"
                                />
                                <small class="text-primary">
                                  {{ $t("adsDisplay.adsPosition-description") }}</small><br>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="3">
                            <b-form-group :label="$t('adsDisplay.mobileType')">
                              <b-form-checkbox
                                v-model="isMobileType"
                                switch
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group :label="$t('adsDisplay.webType')">
                              <b-form-checkbox
                                v-model="isWebType"
                                switch
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <small
                          v-if="!isValid"
                          class="text-danger"
                        >
                          {{ $t('adsDisplay.atLeatsSelected') }}
                        </small>
                      </b-card>
                      <b-col
                        v-if="isMobileType"
                        cols="12"
                      >
                        <hr class="my-4">
                      </b-col>
                      <b-row v-if="isMobileType">
                        <b-col
                          cols="12"
                          class="ml-2"
                        >
                          <h5 class="mb-0">
                            {{ $t("adsDisplay.adsMobileConfig") }}
                          </h5>
                          <small class="text-muted">
                            {{ $t("adsDisplay.adsMobileConfigSub") }}
                          </small>
                        </b-col>
                        <b-col
                          :cols="12"
                          :md="12"
                        >
                          <b-card
                            class="h-100"
                          >
                            <!-- Mobile ID -->
                            <b-row>
                              <b-col cols="6">
                                <validation-provider
                                  v-slot="validationContext"
                                  name="adUnitId"
                                  rules="checkIdRule"
                                >
                                  <b-form-group
                                    :label="$t('adsDisplay.adsUnitId')"
                                    :invalid-feedback="$t('required')"
                                  >
                                    <b-form-input
                                      id="adUnitId"
                                      v-model="adUnitId"
                                      autocomplete="new-password"
                                      :state="validationContext.errors.length > 0 ? false : null"
                                      :placeholder="$t('adsDisplay.adsUnitId-description')"
                                    />
                                    <small
                                      v-if="validationContext.errors.length <= 0"
                                      class="text-primary"
                                    >{{ $t("adsDisplay.adsUnitId-description") }}</small>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col md="6">
                                <b-form-group :label="$t('adsDisplay.adsLocation')">
                                  <validation-provider
                                    v-slot="validationContext"
                                    name="location"
                                    rules="required"
                                  >
                                    <b-form-select
                                      v-model="location"
                                      :disabled="true"
                                      :options="locationOptions"
                                    />
                                    <small class="text-primary">{{ $t("adsDisplay.adsLocation-description") }}</small><br>
                                    <small class="text-danger">{{ inputDecimalNumber(validationContext) }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-card>
                        </b-col>
                      </b-row>
                      <b-col
                        v-if="isWebType"
                        cols="12"
                      >
                        <hr class="my-4">
                      </b-col>
                      <b-row v-if="isWebType">
                        <b-col
                          cols="12"
                          class="ml-2"
                        >
                          <h5 class="mb-0">
                            {{ $t("adsDisplay.webConfigTitle") }}
                          </h5>
                          <small class="text-muted">
                            {{ $t("adsDisplay.webConfigSub") }}
                          </small>
                        </b-col>
                        <b-col
                          :cols="12"
                          :md="12"
                        >
                          <b-card
                            class="h-100"
                            :header="$t('adsDisplay.slotsModalSub')"
                            header-class="font-weight-bold"
                          >
                            <!-- Search Input -->
                            <b-row class="mb-2">
                              <b-col cols="9">
                                <b-form-input
                                  v-model="searchInput"
                                  :placeholder="$t('adsDisplay.searchSlot')"
                                  autofocus
                                  autocomplete="off"
                                />
                              </b-col>
                              <b-col>
                                <b-button
                                  class="ml-2"
                                  variant="primary"
                                  @click.stop.prevent="showModal('slot')"
                                >
                                  {{ $t('adsDisplay.slotCreate') }}
                                </b-button>
                              </b-col>
                            </b-row>
                            <!-- Slot Table -->
                            <b-table
                              id="slot-table"
                              ref="slotTable"
                              :items="dataArray"
                              responsive
                              :fields="columns"
                              class="mb-1 tableHeight"
                              show-empty
                              :empty-text="$t('dataNotFound')"
                              selectable
                              :per-page="15"
                              :current-page="currentPage"
                              @row-selected="onRowSelected"
                            >
                              <template #cell(selected)="{ rowSelected }">
                                <template v-if="rowSelected">
                                  <span
                                    class="text-success"
                                    aria-hidden="true"
                                  >&check;</span>
                                </template>
                                <template v-else>
                                  <span aria-hidden="true">&nbsp;</span>
                                </template>
                              </template>
                              <template #cell(name)="data">
                                <div class="d-flex align-items-center">
                                  {{ data.item.node.slotId }}
                                </div>
                              </template>
                              <template #cell(displayAd)="data">
                                <div
                                  v-if="data.item.node.displayAd && data.item.node.displayAd.name === ads.name"
                                  class="d-flex align-items-center"
                                >
                                  <strong
                                    class="mr-50"
                                  >
                                    {{ $t('adsDisplay.currentAdDisplay') }}
                                  </strong>
                                </div>
                                <div
                                  v-else-if="data.item.node.displayAd"
                                  class="d-flex align-items-center"
                                >
                                  <span
                                    class="mr-50"
                                  >
                                    {{ data.item.node.displayAd.name }}
                                  </span>
                                  <b-link
                                    style="color:light; text-decoration: underline;"
                                    class="cursor-pointer"
                                    :href="'/displayAdvertising/ads/edit/' + data.item.node.displayAd.id"
                                    target="_blank"
                                  >
                                    <feather-icon icon="ExternalLinkIcon" />
                                  </b-link>
                                </div>
                              </template>
                              <template #cell(defaultSize)="data">
                                <div class="d-flex align-items-center">
                                  {{ data.item.node.defaultSize }}
                                </div>
                              </template>
                              <template #cell(linkedSizeMapping)="data">
                                <div class="d-flex align-items-center">
                                  {{ data.item.node.sizeMapping.name }}
                                </div>
                              </template>
                              <template #cell(actions)="data">
                                <div class="text-nowrap">
                                  <b-button
                                    class="mr-1"
                                    variant="outline-success"
                                    size="sm"
                                    @click="editSlot(data.item.node)"
                                  >
                                    <feather-icon icon="EditIcon" />
                                  </b-button>
                                  <b-button
                                    variant="outline-danger"
                                    size="sm"
                                    @click="deleteSlot(data.item.node.id)"
                                  >
                                    <feather-icon icon="Trash2Icon" />
                                  </b-button>
                                </div>
                              </template>
                            </b-table>
                            <small
                              v-if="isEmpty(slotsSelected) && isWebType"
                              class="text-danger"
                            >{{ $t("adsDisplay.atLeatsOneSlot") }}</small>
                            <!-- Pagination -->
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="dataArray ? dataArray.length : 0"
                              :per-page="15"
                              align="center"
                              size="sm"
                              class="mt-2"
                            />
                          </b-card>
                        </b-col>
                      </b-row>
                      <b-col style="text-align: end;">
                        <b-button
                          variant="success"
                          type="submit"
                        >
                          {{
                            $t("dataGeneric.save")
                          }}
                        </b-button>
                      </b-col>
                    </form>
                  </validation-observer>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <b-col md="6">
      <div class="d-block text-center">
        <slot-creator-modal
          :fetched-slot="slotToEdit"
          @confirm-selection="onConfirmSelection"
          @update-slots="onConfirmSelection"
          @modal-closed="hideModal('slot')"
        />
      </div>
    </b-col>
    <b-modal
      ref="CatModal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :root-categories="true"
          :columns="[
            { key: 'image', label: $t('contents.category') },
            { key: 'name', label: $t('firstName') },
            { key: 'type', label: $t('type') },
          ]"
          @confirm-selection="getCategory"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="hideModal('cat')"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>
<script>
import {
  BFormTextarea,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BPagination,
  BLink,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import {
  messageError, showToast, inputDecimalNumber, isEmpty,
} from '@/store/functions'

import { getUserData } from '@/auth/utils'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import CategorySelector from '@/views/common/CategorySelector.vue'
import SlotCreatorModal from '@/views/common/SlotCreatorModal.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormSelect,
    BTable,
    BPagination,
    BLink,
    CategorySelector,
    SlotCreatorModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputDecimalNumber,
      required,
      video: null,
      ads: null,
      name: null,
      description: null,
      isActive: null,
      showDatos: false,
      userData: getUserData(),
      locationOptions: [
        { value: 'TOP', text: this.$t('top') },
        { value: 'MID', text: this.$t('middle') },
        { value: 'BOT', text: this.$t('bottom') },
      ],
      adUnitId: '',
      location: 'MID',
      positionCategoryOptions: [
        { value: 'PRE', text: this.$t('adsDisplay.positionPre') },
        { value: 'POS', text: this.$t('adsDisplay.positionPos') },
      ],
      positionCategory: 'PRE',
      rootCategory: null,
      rootCategoryName: '',
      columns: [
        { key: 'selected', label: this.$t('adsDisplay.selected') },
        { key: 'name', label: this.$t('adsDisplay.slotId') },
        { key: 'displayAd', label: this.$t('adsDisplay.displayAdSlot') },
        { key: 'defaultSize', label: this.$t('adsDisplay.defaultSize') },
        { key: 'linkedSizeMapping', label: this.$t('adsDisplay.linkedSizeMapping') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },

      ],
      searchInput: '',
      dataArray: [],
      currentPage: 1,
      slotsSelected: [],
      initialSlots: [],
      table: null,
      slotToEdit: null,
      isWebType: false,
      isMobileType: false,
    }
  },
  computed: {
    isValid() {
      // At least one checkbox must be checked
      return this.isMobileType || this.isWebType
    },
    isFormValid() {
      const isMobileValid = !this.isMobileType || this.adUnitId !== ''
      const isWebValid = !this.isWebType || !this.isEmpty(this.slotsSelected)
      const areBothValid = !(this.isMobileType && this.isWebType)
        || (this.adUnitId !== '' && !this.isEmpty(this.slotsSelected))
      return this.isValid && isMobileValid && isWebValid && areBothValid
    },
  },
  watch: {
    timeout() {
      if (this.timeout.match(/\d+$/) === null) this.timeout = ''
    },
    prints() {
      if (this.prints.match(/^\d+$/) === null) this.prints = ''
    },
    searchInput() {
      this.fetchSlots()
    },
    slotsSelected() {
      this.$refs.ConfigRules.validate()
    },
    isWebType(newValue) {
      if (newValue === true) {
        this.$nextTick(() => {
          this.setSlot(this.initialSlots)
        })
      }
    },
  },
  async mounted() {
    await this.fetchSlots()
    await this.getData()
    extend('checkIdRule', {
      validate: () => this.adUnitId !== '' && this.isMobileType,
      computesRequired: true,
    })
  },
  methods: {
    isEmpty,
    validationFormConfig() {
      return new Promise((resolve, reject) => {
        this.$refs.ConfigRules.validate().then(success => {
          if (success && this.isFormValid) {
            this.checkSlots()
          } else {
            reject()
          }
        })
      })
    },
    onRowSelected(items) {
      this.slotsSelected = items
    },
    async getData() {
      const { id } = this.$route.params

      try {
        const result = await axios.post('', {
          query: `
        query {
          allDisplayAds(id:"${id}", client:"${this.userData.profile.client.id}") {
            edges {
              node {
                id
                name
                description
                targetMobileActive
                targetWebActive
                location
                adUnitId
                campaign {
                  name
                }
                isActive
                positionCategory
                category {
                  id
                  name
                  isActive
                  state
                }
                slots {
                  edges {
                    node {
                      id
                      slotId
                      defaultSize
                      sizeMapping {
                        id
                        name
                        mappings
                      }
                      displayAd {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
        })

        // Handle the response
        messageError(result, this)

        // Extract and assign data
        this.ads = result.data.data.allDisplayAds.edges[0].node

        this.name = this.ads.name
        this.description = this.ads.description
        this.location = this.ads.location
        this.adUnitId = this.ads.adUnitId
        this.displayCampaign = this.ads.displayCampaign
        this.positionCategory = this.ads.positionCategory
        this.rootCategory = this.ads.category
        this.rootCategoryName = this.rootCategory?.name
        this.isActive = this.ads.isActive
        this.initialSlots = this.ads.slots.edges.map(item => item.node)
        this.isMobileType = this.ads.targetMobileActive
        this.isWebType = this.ads.targetWebActive

        if (this.isWebType) {
          this.setSlot(this.initialSlots)
        }
      } catch (error) {
        console.error(error)
      }
    },
    routeToEditView() {
      window.history.back()
    },
    selectSlots(index) {
      if (this.ads.slots && index === 1) {
        this.slotsSelected = []
        const slots = []
        this.ads.slots.edges.forEach(item => {
          slots.push(item.node)
        })
        if (slots.length > 0) {
          this.setSlot(slots)
        }
      }
    },
    updateInfo() {
      const { id } = this.$route.params
      this.showDatos = true
      axios
        .post('', {
          query: `
          mutation{
            updateDisplayAds(id:"${id}",input:{
              name:"${this.name}",            
              description:"${this.description}",
              isActive:${this.isActive},
            }){
              displayAd
              {                      
                name
                client{
                  id
                  name
                }
                
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.showDatos = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 0, this)
          this.showDatos = false
        })
    },
    checkSlots() {
      const alreadyAssignedSlots = this.slotsSelected.filter(slot => slot.node.displayAd !== null && this.ads.id !== slot.node.displayAd.id)

      if (isEmpty(alreadyAssignedSlots) || !this.isWebType) {
        this.removeDisplayAdSlots(this.initialSlots)
        return
      }

      this.$swal({
        title: this.$t('adsDisplay.assignedSlots'),
        text: this.$t('adsDisplay.changeAssignedSlots'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            const slotsToDelete = [...alreadyAssignedSlots.map(element => element.node), ...this.initialSlots]
            this.removeDisplayAdSlots(slotsToDelete)
          }
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('code.errorCre'), 2, this)
          this.show = false
        })
    },
    removeDisplayAdSlots(assignedSlots) {
      if (isEmpty(assignedSlots)) {
        this.updateConfig()
        return
      }
      this.show = true
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      let query = 'mutation {'
      for (let i = 0; i < assignedSlots.length; i += 1) {
        query += `
        m${i + 1}: ${'updateSlot'}(id: "${assignedSlots[i].id}", input:{displayAd: null}) {
          slot{
            id
          }
        }
      `
      }
      query += '}'
      data.append('query', query)
      const { headers } = this
      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this).then(r => {
            if (!r) {
              this.updateConfig()
            }
          }).catch(() => {
            showToast(this.$t('error'), 2, this)
          })

          this.show = false
        })
        .catch(error => {
          this.show = false
          console.log(error)

          showToast(this.$t('error'), 2, this)
        })
    },
    updateConfig() {
      const { id } = this.$route.params
      this.showDatos = true

      axios
        .post('', {
          query: `
          mutation{
            updateDisplayAds(id:"${id}",input:{
              location:${this.location},              
              adUnitId:"${this.isMobileType ? this.adUnitId : ''}",
              positionCategory: ${this.positionCategory},
              category: ${this.rootCategory.id},
              targetMobileActive: ${this.isMobileType},
              targetWebActive: ${this.isWebType},
              ${!this.rootCategory.isActive || this.rootCategory.state !== 'GRE' ? ',isActive:false' : ''}
            }){
              displayAd
              {                      
                name
                client{
                  id
                  name
                }
                
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.showDatos = false
          if (!isEmpty(this.slotsSelected) && this.isWebType) this.assignSlots(this.slotsSelected.map(item => item.node.id), id)
          else {
            this.getData()
            this.fetchSlots()
          }
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 0, this)
          this.showDatos = false
        })
    },
    assignSlots(slots, displayAd) {
      this.show = true
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      let query = 'mutation {'
      for (let i = 0; i < slots.length; i += 1) {
        query += `
        m${i + 1}: ${'updateSlot'}(id: "${slots[i]}", input:{displayAd:"${displayAd}"}){
          slot{
            id
          }
        }
      `
      }
      query += '}'
      data.append('query', query)
      const { headers } = this
      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this).then(r => {
            if (!r) {
              this.fetchSlots()
              this.getData()
            }
          }).catch(() => {
            showToast(this.$t('error'), 2, this)
          })

          this.show = false
        })
        .catch(error => {
          this.show = false
          console.log(error)

          showToast(this.$t('error'), 2, this)
        })
    },
    checkCategoryState(data) {
      if (data) {
        return !data.isActive || data.state !== 'GRE'
      }
      return true
    },
    saveRange(node = null) {
      this.positionCategory = node
    },
    getCategory(item) {
      this.rootCategory = item.node
      this.rootCategoryName = this.rootCategory?.name
      this.hideModal('cat')
    },
    showModal(type) {
      switch (type) {
        case 'cat':
          this.$refs.CatModal.show()
          break
        case 'slot':
          this.$bvModal.show('slot-creator-modal')
          break
        default:

          break
      }
    },
    hideModal(type) {
      switch (type) {
        case 'cat':
          this.$refs.CatModal.hide()
          break
        case 'slot':
          this.slotToEdit = null
          this.$bvModal.hide('slot-creator-modal')
          break
        default:
          break
      }
    },
    setSlot(items) {
      if (items.length > 0) {
        items.forEach(item => {
          const matchingItem = this.dataArray.find(data => data.node.id === item.id)
          if (matchingItem) {
            this.slotsSelected.push(matchingItem)
            this.$refs.slotTable?.selectRow(this.dataArray.indexOf(matchingItem))
          }
        })
      }
      this.hideModal('slot')
    },
    async fetchSlots() {
      try {
        const res = await axios.post('', {
          query: `
        query {
          allSlots(client:"${this.userData.profile.client.id}", slotId: "${this.searchInput}", orderBy:"slotId") {
            edges {
              node {
                id
                slotId
                defaultSize
                displayAd {
                  id
                  name
                }
                sizeMapping {
                  id
                  name
                  mappings
                }
              }
            }
          }
        }
      `,
        })

        messageError(res, this)
        this.dataArray = res.data.data.allSlots.edges
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    deleteSlot(slotId) {
      this.slotsSelected = this.slotsSelected.filter(item => item.node.id !== slotId)

      axios
        .post('', {
          query: `
            mutation{
              deleteSlot(id: "${slotId}") {
                found
                deletedId
              }
            }
          `,
        })
        .then(response => {
          this.getData()
          this.onConfirmSelection()
          if (response.data.data.deleteSlot.found)showToast(this.$t('adsDisplay.slotDeleted'), 1, this)
          else showToast(this.$t('adsDisplay.slotDeletedError'), 2, this)
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('adsDisplay.slotDeletedError'), 2, this)
        })
    },
    editSlot(slot) {
      this.slotToEdit = slot
      this.showModal('slot')
    },
    async onConfirmSelection(slotCreated = null) {
      try {
        let currentSelectedSlots = []
        if (!isEmpty(this.slotsSelected)) {
          currentSelectedSlots = [...this.slotsSelected.map(element => element.node)]
        }
        await this.fetchSlots()
        if (slotCreated)currentSelectedSlots.push(slotCreated)

        this.setSlot(currentSelectedSlots)
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#ads-edit .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#ads-edit .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#ads-edit .card-body h4 {
  font-size: 1.286rem !important;
}

#ads-edit .tamanio {
  width: 45vw;
  height: 30vw;
}

#ads-edit .root-category {
  // display: flex;
  align-items: center !important;
  width: 70% !important;
  font-weight: bold !important;
  color: aquamarine !important;
  font-size: smaller !important;
}

#ads-edit .head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 1200px) {
  #ads-edit .fullscreen-modal .modal-dialog {
    width: 1170px;
  }

  #ads-edit .range-slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px;
  }

  #ads-edit .range-input {
    width: 100%;
    margin: 20px 0;
    -webkit-appearance: none;
    background: transparent;
  }

  #ads-edit .range-input::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: #7367f0;
    border-radius: 5px;
  }

  #ads-edit .range-input::-webkit-slider-thumb {
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  }

  #ads-edit .range-input:focus::-webkit-slider-runnable-track {
    background: #7367f0;
  }

  #ads-edit .range-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: -10px;
  }
}
</style>
